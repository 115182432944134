<template>
  <div>
    <div class="mx-3 mt-4 mb-8">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#fde0ce"
                    size="48"
                  ><v-icon
                    color="#de6f2a"
                    dark
                  >fas fa-chart-line</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ data.products }}</div>
                  <div class="text-truncate">Products</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#d3f0ff"
                    size="48"
                  ><v-icon
                    color="#0081ba"
                    dark
                  >fas fa-chart-line</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ data.variants }}</div>
                  <div class="text-truncate">Variants</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#c2e5cc"
                    size="48"
                  ><v-icon
                    color="#00972d"
                    dark
                  >fas fa-chart-line</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ data.prices }}</div>
                  <div class="text-truncate">Prices</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#e2c0e0"
                    size="48"
                  ><v-icon
                    color="#830d7f"
                    dark
                  >fas fa-chart-line</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ data.suppliers }}</div>
                  <div class="text-truncate">Suppliers</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  components: {
  },
  setup() {

    const api = inject('api')

    const dashboardModel = api.model('users/dashboard')
    const { attributes: data } = dashboardModel

    dashboardModel.find()




    return {
      data,
    }

  },
}
</script>


<style scoped>


</style>
